// Future TODOs:
// (1) Functionality - implement the timeline block
// (2) Functionality - implement the 'add task' feature
// (3) Functionality - implement the 'edit / clear task data' (name, date, assignment, etc.) feature
// (4) Functionality - implement the 'search' feature
// (5) Functionality - implement the 'unassigned tasks drawer' feature
// (6) Functionality - implement the 'unassigned sprints drawer' feature
// (7) Functionality - add a timed notifications mechanism under the header
// (8) Refactoring - use OOP
// (9) Refactoring - clear class names
// (10) Refactoring - combine / break down functions
// (11) Refactoring - write better comments and variable names
// (12) Functionality - handle and display 'todo' tasks from other phases  
// (13) Functionality - implement lazy loading features
// (14) Functionality - check if better methods can be used for some functions (especially sorting)
// (15) Functionality - implement caching
// (16) Refactoring - consider switching to handlebars
// (17) Functionality - implement a drag and drop for tasks between days
// (18) Functionality - add an 'undo' feature and button / notice
// (19) Functionality - implement a way to view completed tasks
// (20) Functionality - implement the 'dependant tasks' feature
// (21) Functionality - implement the 'dependant sprints' feature
// (22) Functionality - implement the 'recurring tasks' feature
// (23) Functionality - implement the 'recurring sprints' feature
// (24) Functionality - add hours support to tasks
// (25) Refactoring - implement a queue mechanism for update and delete requests

// Set the global app data and vars
var oauth_data      = {
        'client_id': '353598233483-9ipp7ae6qkvds0pg3vp1oeqcb98j5i99.apps.googleusercontent.com',
        'scopes': 'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/calendar'
    },
    drive_folder_id = '18TZlJYkomzBsuZU6UdkRuUflbR7UAn9Q'
    gcalendar_id    = 'c_0f788232e889c5ed08c561c9c8db3829569b109e0eeb6cb54c751247df8ba0ec@group.calendar.google.com';

var priorities_map = {
    'גבוה': 'high', 
    'בינוני': 'medium', 
    'נמוך': 'low'
},
    days_map       = [
        'ראשון',
        'שני',
        'שלישי',
        'רביעי',
        'חמישי',
        'שישי',
        'שבת'
    ],
    months_map     = [
        'ינואר',
        'פברואר',
        'מרץ',
        'אפריל',
        'מאי',
        'יוני',
        'יולי',
        'אוגוסט',
        'ספטמבר',
        'אוקטובר',
        'נובמבר',
        'דצמבר'
    ],
    sprints        = [];

// On mobile - toggle the search bar on header icon click
$('.do-header__actions-menu_mobile-search').on('click', function() {

    if($(window).width() <= 1024) {
        $('.do-header__search').stop().slideToggle(200);
    }
});

// Set the global 'init' function that runs after the Google API
// has been loaded successfully.
function do_init() {

    // Reauth with Google if there is no token cookie present
    if(! Cookies.get('do-google-token') || Cookies.get('do-google-token') === undefined) {
        render_google_onetap_login();
    } else { // If the cookie is present, just load the app
        load_data_and_display_timeline();
    }
}

// Renders the Google One-Tap login modal, but 
// fallbacks to the regular dialog if it fails to load.
function render_google_onetap_login() {

    // Init and render the Google One-tap login modal
    google.accounts.id.initialize({
        client_id: oauth_data.client_id,
        callback: handle_user_login,
        ux_mode: 'popup'
    });

    google.accounts.id.prompt(function(notification) {

        // If the modal is not displayed for some reason,
        // call the handle_user_login callback which will redirect the user to
        // the login and permissions granting pages.
        if(notification.isNotDisplayed() || notification.isSkippedMoment()) {
            handle_user_login(null);
        }
    });
}

// Used to get the access token for the Google API actions
// (following a first time auth or if a refreshed token is needed)
function handle_user_login(data) {

    var client_config = {
        client_id: oauth_data.client_id,
        scope: oauth_data.scopes,
        prompt: '',
        error_callback: function() {
            window.reload();
        },
        callback: function(token_response) {

            // Save the token in a cookie that expires 5 seconds before the token expires
            Cookies.set('do-google-token', token_response.access_token, { 
                expires: new Date(new Date().getTime() + (token_response.expires_in * 1000 - 5)), 
                path: '/' 
            });

            // Load the app and fetch the data for it
            load_data_and_display_timeline();
        }
    };

    // If this request follows the One Tap login request,
    // send a hint to Google with the email of the user for
    // which we are requesting the consent.
    if(data !== null) {
        client_config.hint = jwt_decode(data.credential).email;
    }

    client = google.accounts.oauth2.initTokenClient(client_config);
    client.requestAccessToken();
}

// Fetch and parse the data from Google Drive and Sheets,
// and use that data to load the app's components and elements.
function load_data_and_display_timeline() {

    // Start by fetching the list of sheets inside the configured folder
    $.ajax({
        url: 'https://www.googleapis.com/drive/v3/files?q=\'' + drive_folder_id + '\'+in+parents',
        type: 'GET',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {

            // Go over the data returned, if exists, and call the Sheets API
            // for each sheet to check if it fits the current focus timeframe
            var tasks_data = prepare_tasks_data(
                collect_sheet_ids(data.files)
            );
            
            // Fill the tasks list with the data, 
            // starting from today (ignore old tasks)
            build_tasks_list(tasks_data.focus);

            // Create the timeline with all of the sprints in the
            // current focus (including old tasks)

            // After all is done, hide the loader and remove the 'is-loading'
            // class from the body
            $('.do-loader--general').hide();
            $('body').removeClass('is-loading');
        }
    });
}

// Get the IDs from the data returned from the Drive folder
function collect_sheet_ids(files) {

    var sheets_data;

    if(files && files.length > 0) {

        sheets_data = {
            unassigned_id: '',
            rest_ids: []
        };

        $.each(files, function() {
            
            // Skip non-Sheets files
            if(this.mimeType == 'application/vnd.google-apps.spreadsheet') {

                // Also, append the 'unassigned tasks' sheet in the relevant variable
                if(this.name == 'Unassigned Items') {
                    sheets_data.unassigned_id = this.id;
                } else {
                    sheets_data.rest_ids.push(this.id);
                }
            }
        });        
    }

    return sheets_data;
}

// Prepare the tasks data by calling the Sheets API to get the
// sheet contents and to locate the relevant focus
function prepare_tasks_data(ids) {

    var data = {
        unassigned: [],
        focus: []
    };

    if(ids && Object.values(ids).length > 0) {

        // First, handle the unassigned tasks
        data.unassigned = fetch_data_from_sheet(ids.unassigned_id, 'Unassigned Items');

        // Then, locate the relevant focus sheet and get it's data
        var focus_sheet = locate_current_focus_sheet(ids.rest_ids);

        data.focus = cast_data_from_sheet(
            focus_sheet.id,
            null,
            focus_sheet.data
        );
    }

    return data;
}

// Get a sheet ID and return the data from it according to the
// structure the app uses
function fetch_data_from_sheet(id, name) {

    var tasks_data = [];

    $.ajax({
        url: 'https://sheets.googleapis.com/v4/spreadsheets/' + id + '?fields=sheets.properties.title,sheets.properties.sheetId,sheets.data.rowData.values.userEnteredValue',
        type: 'GET',
        async: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {
            tasks_data = cast_data_from_sheet(id, name, data);
        }
    });

    return tasks_data;
}

// Convert a sheet's data to the desired structure used by the app
function cast_data_from_sheet(id, name, data) {

    var tasks_data = [];

    if(data.sheets && data.sheets.length > 0) {

        var tab_name = '',
            tab_id   = '';

        $.each(data.sheets, function() {

            tab_name = this.properties.title;
            tab_id   = this.properties.sheetId;

            // Add the sprint to the sprints array
            if(
                sprints.find(x => x.name === tab_name) === undefined &&
                name !== 'Unassigned Items'
            ) {

                sprints.push({
                    name: tab_name,
                    tab_id: tab_id
                });
            }

            // Cast the tasks data
            tasks_data[tab_name] = {
                sheet_id: id,
                tasks: []
            }

            $.each(this.data[0].rowData, function(index) {

                // Skip the 1st row as it's the keys row and doesn't
                // contain any relevant task data
                if(
                    index !== 0 &&
                    this.values[1] && Object.keys(this.values[1]).length > 0 // TEMP: skip tasks without a date
                ) {

                    tasks_data[tab_name].tasks.push({
                        tab_id: tab_id,
                        row_id: index,
                        title: this.values[0].userEnteredValue.stringValue,
                        date: this.values[1].userEnteredValue.stringValue,
                        priority: this.values[2].userEnteredValue.stringValue,
                        status: this.values[3] && Object.values(this.values[3]).length > 0 ? 'done' : 'todo',
                        completion_date: this.values[4] && Object.values(this.values[4]).length > 0 ? this.values[4].userEnteredValue.stringValue : '',
                        custom_order: this.values[5] && Object.values(this.values[5]).length > 0 ? this.values[5].userEnteredValue.numberValue : 99999,
                        is_using_custom_order: this.values[5] && Object.values(this.values[5]).length > 0
                    });
                }
            });
        });
    }

    // Assign additional sprint params (not used in the Unassigned Tasks sprint)
    if(name !== 'Unassigned Items') {

        // Call the Lambda function and fetch a list of random colors
        // according to the number of sprints available
        var sprint_colors = fetch_random_colors(sprints.length);

        // Go over the sprints array and set the start and end dates,
        // and assign a color from the previously fetched array
        $.each(sprints, function(index) {

            // Set the dates
            tasks_data[this.name].tasks.sort(function (a, b) {
                return strtotime(a.date) - strtotime(b.date);
            });

            this.start_date   = new Date(strtotime(tasks_data[this.name].tasks[0].date));
            this.end_date     = new Date(strtotime(tasks_data[this.name].tasks[tasks_data[this.name].tasks.length - 1].date));
            this.sprint_color = sprint_colors[index];
        });

        // Call the task creation dropdown population function
        populate_task_creation_sprints_dropdown();
    }

    return tasks_data;
}

// Locate the current focus sheet by going over all of the tasks
// and seeking for a task with the relevant current timeframe
function locate_current_focus_sheet(ids) {

    var ranges_data   = {},
        current_sheet = {};

    $.each(ids, function() {

        var sheet_id = this;
        ranges_data[sheet_id] = {};

        $.ajax({
            url: 'https://sheets.googleapis.com/v4/spreadsheets/' + this + '?fields=sheets.properties.title,sheets.properties.sheetId,sheets.data.rowData.values.userEnteredValue',
            type: 'GET',
            async: false,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));
            },
            error: function(data) {
                console.log(data);
            },
            success: function(data) {
    
                if(data.sheets && data.sheets.length > 0) {

                    $.each(data.sheets, function() {

                        $.each(this.data[0].rowData, function(index) {

                            // Skip the 1st row as it's the keys row and doesn't
                            // contain any relevant task data
                            if(
                                index !== 0 &&
                                this.values[1] && Object.keys(this.values[1]).length > 0 // Also, skip tasks without a date as we can't use it in the calculation
                            ) {

                                // Check if the task date is lower than the min date in the set for this sheet,
                                // replace it in the set if it is.
                                if((! ranges_data[sheet_id].min || ranges_data[sheet_id].min > strtotime(this.values[1].userEnteredValue.stringValue))) {
                                    ranges_data[sheet_id].min  = strtotime(this.values[1].userEnteredValue.stringValue);
                                    ranges_data[sheet_id].data = data;
                                }

                                // Check if it's larger than the maximum and do the same
                                if((! ranges_data[sheet_id].max || ranges_data[sheet_id].max < strtotime(this.values[1].userEnteredValue.stringValue))) {
                                    ranges_data[sheet_id].max  = strtotime(this.values[1].userEnteredValue.stringValue);
                                    ranges_data[sheet_id].data = data;
                                }
                            }
                        });
                    });
                }
            }
        });    
    });

    // Go over the ranges and check whether the current time falls in one of them
    var now_time = new Date();
    now_time.setHours(0, 0, 0, 0);

    $.each(ranges_data, function(sheet_id) {

        if(
            now_time.getTime() >= this.min &&
            now_time.getTime() <= this.max
        ) {
            current_sheet = {
                id: sheet_id,
                data: this.data
            };

            return;
        }
    });

    return current_sheet;
}

// Build the tasks list structure from the Sheets data -
// group by date and set the 'done' tasks to be last
function build_tasks_list(data) {
    
    var task_date,
        task_completed_date,
        dated_tasks    = {},
        all_dates      = [],
        now_time       = new Date(),
        tasks_html     = '',
        sheet_id;

    now_time.setHours(0, 0, 0, 0);
    now_time = now_time.getTime();

    $.each(Object.values(data), function(index) {
        sheet_id = this.sheet_id;

        $.each(this.tasks, function() {

            task_date = new Date(strtotime(this.date));
            task_date.setHours(0, 0, 0, 0);
            task_date = task_date.getTime();

            if(task_date >= now_time) {

                if(! dated_tasks[task_date] || dated_tasks[task_date].length <= 0) {
                    dated_tasks[task_date] = {};
                }

                if(! dated_tasks[task_date][this.status] || dated_tasks[task_date][this.status].length <= 0) {
                    dated_tasks[task_date][this.status] = [];
                }

                dated_tasks[task_date][this.status].push(Object.assign({}, {
                    sheet_id: sheet_id,
                    sprint_name: Object.keys(data)[index]
                }, this));

            } else if(this.status == 'todo') {

                if(! dated_tasks[now_time] || dated_tasks[now_time].length <= 0) {
                    dated_tasks[now_time] = {};
                }

                if(! dated_tasks[now_time]['overdue'] || dated_tasks[now_time]['overdue'].length <= 0) {
                    dated_tasks[now_time]['overdue'] = [];
                }

                dated_tasks[now_time]['overdue'].push(Object.assign({}, {
                    sheet_id: sheet_id,
                    sprint_name: Object.keys(data)[index]
                }, this));  

            } else if(this.status == 'done') {

                task_completed_date = new Date(strtotime(this.completion_date.split(' ')[0]));
                task_completed_date.setHours(0, 0, 0, 0);
                task_completed_date = task_completed_date.getTime();

                // Completion date should be greater than today as well
                if(task_completed_date >= now_time) {

                    if(! dated_tasks[task_completed_date] || dated_tasks[task_completed_date].length <= 0) {
                        dated_tasks[task_completed_date] = {};
                    }
    
                    if(! dated_tasks[task_completed_date][this.status] || dated_tasks[task_completed_date][this.status].length <= 0) {
                        dated_tasks[task_completed_date][this.status] = [];
                    }
    
                    dated_tasks[task_completed_date][this.status].push(Object.assign({}, {
                        sheet_id: sheet_id,
                        sprint_name: Object.keys(data)[index]
                    }, this));    
                }
            }
        });
    });

    all_dates = Object.keys(dated_tasks);
    all_dates.sort(function(a, b) { return a - b });

    $.each(all_dates, function(index) {

        var list_date           = new Date(Number(this)),
            list_date_formatted = 'יום ' + days_map[list_date.getDay()] + ', ' + list_date.getDate() + ' ב' + months_map[list_date.getMonth()] + ' ' + list_date.getFullYear();

        tasks_html += '<li class="do-tasks__list-day">' +
                '<div class="do-tasks__list-day_heading">' +
                    '<div class="container">' +
                        '<h2>' + list_date_formatted + '</h2>' +
                    '</div>' +
                '</div>' +
                '<ul class="do-tasks__list-day_items">';

        tasks_html += build_tasks_list_inner_html(dated_tasks[this]);

        tasks_html += '</ul>' +
            '</li>';
    });

    $('.do-tasks__list').html(tasks_html);

    // Init the sortable drag and drop
    init_sortable(null);
}

// Organize and sort tasks in a day timeframe
function build_tasks_list_inner_html(tasks_list) {

    var tasks_html = '';

    // Overdue tasks - order by:
    // (0) Custom order; then
    // (1) Due date; then
    // (2) Priority; then
    // (3) Sprint name

    // First, sort without custom order
    if(tasks_list['overdue'] && tasks_list['overdue'].length > 0) {

        tasks_list['overdue'].sort(function (a, b) {

            var date_a_splitted = a.date.split(' '),
                date_b_splitted = b.date.split(' '),
                date_a          = new Date(strtotime(date_a_splitted[0])),
                date_b          = new Date(strtotime(date_b_splitted[0]));

            if(date_a_splitted.length == 2) {
                var time_a = date_a_splitted[1].split(':');
                date_a.setHours(time_a[0], time_a[1], 0, 0);
            } else {
                date_a.setHours(23, 59, 59, 999);
            }

            if(date_b_splitted.length == 2) {
                var time_b = date_b_splitted[1].split(':');
                date_b.setHours(time_b[0], time_b[1], 0, 0);
            } else {
                date_b.setHours(23, 59, 59, 999);
            }

            return (date_a.getTime() - date_b.getTime()) ||
                (Object.keys(priorities_map).indexOf(a.priority) - Object.keys(priorities_map).indexOf(b.priority)) ||
                (sprints.findIndex(x => x.name === a.sprint_name) - sprints.findIndex(x => x.name === b.sprint_name));
        });
        
        // Build the HTML
        tasks_html += build_tasks_list_html('overdue', tasks_list['overdue']);
    }

    // To do tasks - order by:
    // (0) Custom order; then
    // (1) Priority; then
    // (2) Due date; then
    // (3) Sprint name

    // First, sort without custom order
    if(tasks_list['todo'] && tasks_list['todo'].length > 0) {

        tasks_list['todo'].sort(function (a, b) {

            var date_a_splitted = a.date.split(' '),
                date_b_splitted = b.date.split(' '),
                date_a          = new Date(strtotime(date_a_splitted[0])),
                date_b          = new Date(strtotime(date_b_splitted[0]));

            if(date_a_splitted.length == 2) {
                var time_a = date_a_splitted[1].split(':');
                date_a.setHours(time_a[0], time_a[1], 0, 0);
            } else {
                date_a.setHours(23, 59, 59, 999);
            }

            if(date_b_splitted.length == 2) {
                var time_b = date_b_splitted[1].split(':');
                date_b.setHours(time_b[0], time_b[1], 0, 0);
            } else {
                date_b.setHours(23, 59, 59, 999);
            }

            return (Object.keys(priorities_map).indexOf(a.priority) - Object.keys(priorities_map).indexOf(b.priority)) ||
                (date_a.getTime() - date_b.getTime()) || 
                (sprints.findIndex(x => x.name === a.sprint_name) - sprints.findIndex(x => x.name === b.sprint_name));
        });

        // Assign the custom_order params to each task - only current 'todo' tasks can be custom ordered
        tasks_list['todo'] = assign_custom_order_param_to_task_list_item(tasks_list['todo']);

        // Sort again, this time by custom order only (as it was already assigned)
        tasks_list['todo'].sort(function (a, b) {
            return (Number(a.custom_order) - Number(b.custom_order));
        });

        // Build the HTML
        tasks_html += build_tasks_list_html('todo', tasks_list['todo']);
    }

    // Completed tasks - order by completion date only
    if(tasks_list['done'] && tasks_list['done'].length > 0) {

        tasks_list['done'].sort(function (a, b) {  
            
            var date_a = new Date(strtotime(a.completion_date.split(' ')[0])),
                date_b = new Date(strtotime(b.completion_date.split(' ')[0])),
                time_a = a.completion_date.split(' ')[1].split(':'),
                time_b = b.completion_date.split(' ')[1].split(':');
    
            date_a.setHours(time_a[0], time_a[1], time_a[2], 0);
            date_b.setHours(time_b[0], time_b[1], time_b[2], 0);
    
            return (date_a.getTime() - date_b.getTime());
        });
    
        tasks_html += build_tasks_list_html('done', tasks_list['done']);
    }    

    return tasks_html;
}

// Build the HTML for a set of tasks
function build_tasks_list_html(status, items) {

    var tasks_html = '';

    $.each(items, function() {

        var task_date_raw       = strtotime(this.date),
            task_date           = new Date(task_date_raw),
            task_date_formatted = task_date.getDate() + ' ב' + months_map[task_date.getMonth()] + ' ' + task_date.getFullYear();

            if(this.date.split(' ').length == 2) {
                task_date_formatted += ', ' + String(task_date.getHours()).padStart(2, '0') + ':' + String(task_date.getMinutes()).padStart(2, '0');
            }

            tasks_html += '<li' +
                ' class="do-tasks__list-day_item priority--' + priorities_map[this.priority] + ' ' + (status == 'done' ? 'is-complete' : (status == 'overdue' ? 'is-late' : '')) + '"' +
                ' data-sheet-id="' + this.sheet_id + '" data-tab-name="' + this.sprint_name + '" data-tab-id="' + this.tab_id + '" data-row="' + this.row_id + '"' +
                ' data-date="' + this.date + '" data-completion-date="' + this.completion_date + '"' +
                ' data-custom-order="' + this.custom_order + '" data-is-using-custom-order="' + (this.is_using_custom_order ? 'true' : 'false') + '"' +
            '>' +
                '<button class="do-tasks__list-day_item__mobile-delete">' +
                    '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.002" viewBox="0 0 12 12.002">' +
                        '<path data-name="Path 466" d="m143.44 84.9 5.52-5.521-5.52-5.521.479-.479 5.52 5.521 5.521-5.52.479.479-5.521 5.52 5.52 5.521-.479.479-5.52-5.521-5.521 5.52z" transform="translate(-143.44 -73.382)" style="fill:#fff"/>' +
                    '</svg>' +

                    '<figure class="do-loader" style="display: none;">' +
                        '<svg xmlns="http://www.w3.org/2000/svg" width="79.039" height="40" viewBox="0 0 79.039 40">' +
                            '<defs>' +
                                '<clipPath id="hbhzmw0k8a3">' +
                                    '<path data-name="Rectangle 131" style="fill:#fff" d="M0 0h35.034v40H0z"/>' +
                                '</clipPath>' +
                                '<clipPath id="rfdqya6lub3">' +
                                    '<path data-name="Rectangle 128" transform="rotate(30 14.373 53.643)" style="stroke:#707070;fill:#fff" d="M0 0h34.496v57.494H0z"/>' +
                                '</clipPath>' +
                            '</defs>' +
                            '<g data-name="Group 88" transform="translate(-150.228 -72.905)">' +
                                '<circle data-name="Ellipse 2" cx="19.998" cy="19.998" r="19.998" transform="translate(189.271 72.91)" style="fill:#d6e4e5"/>' +
                                '<g data-name="Mask Group 11" transform="translate(150.228 72.905)" style="clip-path:url(#hbhzmw0k8a3)">' +
                                    '<g data-name="Mask Group 9" transform="translate(-3.724 -6.768)" style="clip-path:url(#rfdqya6lub3)">' +
                                        '<circle data-name="Ellipse 3" cx="19.998" cy="19.998" r="19.998" transform="translate(-2.242 6.273)" style="fill:#d6e4e5"/>' +
                                    '</g>' +
                               '</g>' +
                            '</g>' +
                        '</svg>' +
                    '</figure>' +
                '</button>' +

                '<button class="do-tasks__list-day_item__mobile-calendar">' +
                    '<svg id="Google_Calendar_icon__2020_" data-name="Google_Calendar_icon_(2020)" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">' +
                        '<g id="Group_89" data-name="Group 89" transform="translate(3.75 3.75)">' +
                            '<path id="Path_474" data-name="Path 474" d="M65.211,125.276a20.349,20.349,0,0,1-8.145-11.671l9.132-3.763a13.864,13.864,0,0,0,4.342,7.342,11.189,11.189,0,0,0,7.474,2.592,11.519,11.519,0,0,0,7.7-2.724,8.7,8.7,0,0,0,3.224-6.934,8.6,8.6,0,0,0-3.395-7.026,13.171,13.171,0,0,0-8.5-2.724H71.764V91.329H76.5a11.544,11.544,0,0,0,7.382-2.368,7.81,7.81,0,0,0,3-6.487A7.167,7.167,0,0,0,84.2,76.619a10.405,10.405,0,0,0-6.8-2.2A9.149,9.149,0,0,0,71,76.567a12.58,12.58,0,0,0-3.447,5.276L58.514,78.08a20.07,20.07,0,0,1,6.618-8.987q4.836-3.888,12.342-3.895a22.519,22.519,0,0,1,9.974,2.145,17.064,17.064,0,0,1,6.934,5.947,15.179,15.179,0,0,1,2.5,8.539,13.985,13.985,0,0,1-2.329,8.184,16.059,16.059,0,0,1-5.724,5.145V95.7a17.379,17.379,0,0,1,7.342,5.724,15,15,0,0,1,2.868,9.211,17.237,17.237,0,0,1-2.724,9.579,18.863,18.863,0,0,1-7.513,6.618,23.688,23.688,0,0,1-10.776,2.421A22.306,22.306,0,0,1,65.211,125.276Z" fill="#fff"/>' +
                            '<path id="Path_475" data-name="Path 475" d="M121.25,79.961l-9.974,7.25-5.013-7.605L124.25,66.632h6.9v61.2h-9.9Z" fill="#fff"/>' +
                            '<path id="Path_476" data-name="Path 476" d="M148.882,196.25l47.368-47.368-23.684-10.526-23.684,10.526-10.526,23.684Z" fill="#e4ebf5"/>' +
                            '<path id="Path_477" data-name="Path 477" d="M33.092,172.566,43.618,196.25H148.881V148.882H43.618Z" fill="#fff"/>' +
                            '<path id="Path_478" data-name="Path 478" d="M12.039-3.75A15.785,15.785,0,0,0-3.75,12.039V148.881l23.684,10.526,23.684-10.526V43.618H148.881l10.526-23.684L148.882-3.75Z" fill="#fff"/>' +
                            '<path id="Path_479" data-name="Path 479" d="M-3.75,148.882v31.579A15.785,15.785,0,0,0,12.039,196.25H43.618V148.882Z" fill="#e4ebf5"/>' +
                            '<path id="Path_480" data-name="Path 480" d="M148.882,43.618V148.881H196.25V43.618L172.566,33.092Z" fill="#fff"/>' +
                            '<path id="Path_481" data-name="Path 481" d="M196.25,43.618V12.039A15.785,15.785,0,0,0,180.461-3.75H148.882V43.618Z" fill="#e4ebf5"/>' +
                        '</g>' +
                    '</svg>' +
              
                    '<figure class="do-loader" style="display: none;">' +
                        '<svg xmlns="http://www.w3.org/2000/svg" width="79.039" height="40" viewBox="0 0 79.039 40">' +
                            '<defs>' +
                                '<clipPath id="hbhzmw0k8a4">' +
                                    '<path data-name="Rectangle 131" style="fill:#fff" d="M0 0h35.034v40H0z"/>' +
                                '</clipPath>' +
                                '<clipPath id="rfdqya6lub4">' +
                                    '<path data-name="Rectangle 128" transform="rotate(30 14.373 53.643)" style="stroke:#707070;fill:#fff" d="M0 0h34.496v57.494H0z"/>' +
                                '</clipPath>' +
                            '</defs>' +
                            '<g data-name="Group 88" transform="translate(-150.228 -72.905)">' +
                                '<circle data-name="Ellipse 2" cx="19.998" cy="19.998" r="19.998" transform="translate(189.271 72.91)" style="fill:#d6e4e5"/>' +
                                '<g data-name="Mask Group 11" transform="translate(150.228 72.905)" style="clip-path:url(#hbhzmw0k8a4)">' +
                                    '<g data-name="Mask Group 9" transform="translate(-3.724 -6.768)" style="clip-path:url(#rfdqya6lub4)">' +
                                        '<circle data-name="Ellipse 3" cx="19.998" cy="19.998" r="19.998" transform="translate(-2.242 6.273)" style="fill:#d6e4e5"/>' +
                                    '</g>' +
                            '</g>' +
                            '</g>' +
                        '</svg>' +
                    '</figure>' +
                '</button>' +

                '<div class="container">' +
                    '<button class="do-tasks__list-day_item__mark">' +
                        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.501" viewBox="0 0 16 15.501">' +
                            '<path data-name="Path 470" d="m115.335 51.37-7.435-6.022 2.119-2.616 4.476 3.626 6.55-10.489 2.855 1.782z" transform="translate(-107.9 -35.869)"/>' +
                        '</svg>' +
                    '</button>' +
            
                    '<span class="do-tasks__list-day_item__label">' + this.title + '</span>' +
                    '<small class="do-tasks__list-day_item__date">' + task_date_formatted + '</small>' +
                    '<small class="do-tasks__list-day_item__sprint" style="color: #' + sprints.find(x => x.name === this.sprint_name).sprint_color + '">' + this.sprint_name + '</small>' +
                    '<small class="do-tasks__list-day_item__priority">' + this.priority + '</small>' +
            
                    '<button class="do-tasks__list-day_item__calendar">' +
                        '<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"' +
                            'viewBox="0 0 200 200" enable-background="new 0 0 200 200" xml:space="preserve">' +
                            '<g>' +
                                '<g transform="translate(3.75 3.75)">' +
                                    '<path fill="#FFFFFF" d="M148.882,43.618l-47.368-5.263l-57.895,5.263L38.355,96.25l5.263,52.632l52.632,6.579l52.632-6.579' +
                                        'l5.263-53.947L148.882,43.618z"/>' +
                                    '<path fill="#1A73E8" d="M65.211,125.276c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c0.829,3.158,2.276,5.605,4.342,7.342' +
                                        'c2.053,1.737,4.553,2.592,7.474,2.592c2.987,0,5.553-0.908,7.697-2.724s3.224-4.132,3.224-6.934c0-2.868-1.132-5.211-3.395-7.026' +
                                        's-5.105-2.724-8.5-2.724h-5.276v-9.039H76.5c2.921,0,5.382-0.789,7.382-2.368c2-1.579,3-3.737,3-6.487' +
                                        'c0-2.447-0.895-4.395-2.684-5.855s-4.053-2.197-6.803-2.197c-2.684,0-4.816,0.711-6.395,2.145s-2.724,3.197-3.447,5.276' +
                                        'l-9.039-3.763c1.197-3.395,3.395-6.395,6.618-8.987c3.224-2.592,7.342-3.895,12.342-3.895c3.697,0,7.026,0.711,9.974,2.145' +
                                        'c2.947,1.434,5.263,3.421,6.934,5.947c1.671,2.539,2.5,5.382,2.5,8.539c0,3.224-0.776,5.947-2.329,8.184' +
                                        'c-1.553,2.237-3.461,3.947-5.724,5.145v0.539c2.987,1.25,5.421,3.158,7.342,5.724c1.908,2.566,2.868,5.632,2.868,9.211' +
                                        's-0.908,6.776-2.724,9.579c-1.816,2.803-4.329,5.013-7.513,6.618c-3.197,1.605-6.789,2.421-10.776,2.421' +
                                        'C73.408,129.263,69.145,127.934,65.211,125.276z"/>' +
                                    '<path fill="#1A73E8" d="M121.25,79.961l-9.974,7.25l-5.013-7.605l17.987-12.974h6.895v61.197h-9.895L121.25,79.961z"/>' +
                                    '<path fill="#EA4335" d="M148.882,196.25l47.368-47.368l-23.684-10.526l-23.684,10.526l-10.526,23.684L148.882,196.25z"/>' +
                                    '<path fill="#34A853" d="M33.092,172.566l10.526,23.684h105.263v-47.368H43.618L33.092,172.566z"/>' +
                                    '<path fill="#4285F4" d="M12.039-3.75C3.316-3.75-3.75,3.316-3.75,12.039v136.842l23.684,10.526l23.684-10.526V43.618h105.263' +
                                        'l10.526-23.684L148.882-3.75H12.039z"/>' +
                                    '<path fill="#188038" d="M-3.75,148.882v31.579c0,8.724,7.066,15.789,15.789,15.789h31.579v-47.368H-3.75z"/>' +
                                    '<path fill="#FBBC04" d="M148.882,43.618v105.263h47.368V43.618l-23.684-10.526L148.882,43.618z"/>' +
                                    '<path fill="#1967D2" d="M196.25,43.618V12.039c0-8.724-7.066-15.789-15.789-15.789h-31.579v47.368H196.25z"/>' +
                                '</g>' +
                            '</g>' +
                        '</svg>' +
                    '</button>' +

                    '<button class="do-tasks__list-day_item__remove">' +
                        '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.002" viewBox="0 0 12 12.002">' +
                            '<path data-name="Path 466" d="m143.44 84.9 5.52-5.521-5.52-5.521.479-.479 5.52 5.521 5.521-5.52.479.479-5.521 5.52 5.52 5.521-.479.479-5.52-5.521-5.521 5.52z" transform="translate(-143.44 -73.382)" style="fill:#a8c0c1"/>' +
                        '</svg>' +                                  
                    '</button>' +  
                '</div>' +
            '</li>';
    });

    return tasks_html;
}

// Fetch a random pastel color from ColorHunt using the Heroku proxy
function fetch_random_colors(limit) {

    var random_colors = [];

    $.ajax({
        url: 'https://df95dksd50.execute-api.us-east-1.amazonaws.com/do-color-picker?limit=' + limit,
        type: 'GET',
        async: false,
        error: function(data) {
            console.log(data);
        },
        success: function(resp) {
            var data = JSON.parse(resp);

            if(data.colors && data.colors.length > 0) {
                random_colors = data.colors;
            }
        }
    });

    return random_colors;
}

// Get a timestamp from a date, similar to PHP
function strtotime(date) {
    date = date.split(' ');

    if(date.length === 2) {
        time = date[1].split(':');
        date = date[0].split('.');

        return new Date(date[2], date[1] - 1, date[0], time[0], time[1]).getTime();
    }

    date = date[0].split('.');

    return new Date(date[2], date[1] - 1, date[0]).getTime();
}

// On 'mark' click, update the task's status
$('.do-tasks__list').on('mousedown touchend', '.do-tasks__list-day_item .do-tasks__list-day_item__mark', function(e) {
    e.preventDefault();

    var parent_row  = $(this).parents('.do-tasks__list-day_item'),
        daily_list  = parent_row.parents('.do-tasks__list-day_items'),
        title       = parent_row.find('.do-tasks__list-day_item__label').text(),
        update_data = {
            sheet_id: parent_row.attr('data-sheet-id'),
            tab_name: parent_row.attr('data-tab-name'),
            sheet_row: parent_row.attr('data-row'),
            status: parent_row.hasClass('is-complete') ? '' : 'בוצע'
        },
        now_time    = new Date();

    update_data.update_date = (update_data.status !== 'בוצע' ? '' : (now_time.getDate() + '.' + (now_time.getMonth() + 1) + '.' + now_time.getFullYear() + ' ' + String(now_time.getHours()).padStart(2, '0') + ':' + String(now_time.getMinutes()).padStart(2, '0') + ':' + String(now_time.getSeconds()).padStart(2, '0')));

    // Call the Google Sheets API to update the cells
    $.ajax({
        url: 'https://sheets.googleapis.com/v4/spreadsheets/' + update_data.sheet_id + '/values:batchUpdate',
        type: 'POST',
        async: false,
        contentType: 'application/json',
        data: JSON.stringify({
            valueInputOption: 'USER_ENTERED',
            data: [
                {
                    range: update_data.tab_name + '!D' + (Number(update_data.sheet_row) + 1),
                    values: [
                        [update_data.status]
                    ]
                },
                {
                    range: update_data.tab_name + '!E' + (Number(update_data.sheet_row) + 1),
                    values: [
                        [update_data.update_date]
                    ]
                }
            ]        
        }),
        beforeSend: function(xhr) {

            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));

            // Add / remove the 'is-complete' class on the frontend
            parent_row.toggleClass('is-complete');

            // Append the 'completion date' attribute to the element
            parent_row.attr('data-completion-date', update_data.update_date);

            // Move the task to the bottom of the list if it is marked as done
            if(update_data.status == 'בוצע') {
                daily_list.append(parent_row);
            } else { // Locate the relevant location otherwise
                
                // Rebuild the HTML for the whole day to put the task in the correct place
                var tasks_list = {},
                    list_name;

                now_time.setHours(0, 0, 0, 0);
                now_time = now_time.getTime();            

                daily_list.find('.do-tasks__list-day_item').each(function() {

                    list_name = ($(this).hasClass('is-complete') ? 'done' : (strtotime($(this).attr('data-date')) >= now_time ? 'todo' : 'overdue'));
                    
                    if(! tasks_list[list_name] || tasks_list[list_name].length <= 0) {
                        tasks_list[list_name] = [];
                    }

                    tasks_list[list_name].push({
                        sheet_id: $(this).attr('data-sheet-id'),
                        row_id: $(this).attr('data-row'),
                        tab_id: $(this).attr('data-tab-id'),
                        sprint_name: $(this).find('.do-tasks__list-day_item__sprint').text(),
                        title: $(this).find('.do-tasks__list-day_item__label').text(),
                        priority: $(this).find('.do-tasks__list-day_item__priority').text(),
                        date: $(this).attr('data-date'),
                        completion_date: $(this).attr('data-completion-date'),
                        status: (list_name == 'done' ? 'done' : 'todo'),
                        custom_order: $(this).attr('data-custom-order'),
                        is_using_custom_order: $(this).attr('data-is-using-custom-order')
                    });
                });

                daily_list.html(
                    build_tasks_list_inner_html(tasks_list)
                );
            }

            // Get the current sortable and destroy, re-init right afterwards
            var sortable = Sortable.get(daily_list[0]);
            sortable.destroy();

            init_sortable(daily_list);
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {

            // If there are any other pending tasks, collect their indexes into an array
            // which will be sent to Google Sheets to update their custom order field
            var order_data = [];

            if(daily_list.find('.do-tasks__list-day_item:not(.is-complete)') && daily_list.find('.do-tasks__list-day_item:not(.is-complete)').length > 0) {
            // 
                if(update_data.status == 'בוצע') {

                    daily_list.find('.do-tasks__list-day_item:not(.is-complete)').each(function() {

                        order_data.push({
                            range: $(this).attr('data-tab-name') + '!F' + (Number($(this).attr('data-row')) + 1),
                            values: [
                                [$(this).index() + 1]
                            ]
                        });
                    });

                } else {

                    daily_list.find('.do-tasks__list-day_item:not(.is-complete)').not(parent_row).each(function() {

                        if(Number($(this).attr('data-custom-order')) >= Number(parent_row.attr('data-custom-order'))) {

                            order_data.push({
                                range: $(this).attr('data-tab-name') + '!F' + (Number($(this).attr('data-row')) + 1),
                                values: [
                                    [Number($(this).attr('data-custom-order')) + 1]
                                ]
                            });
                        }
                    });

                }

                // Send another request to update the custom order of 
                // the remaining pending items in the Google Sheet
                $.ajax({
                    url: 'https://sheets.googleapis.com/v4/spreadsheets/' + update_data.sheet_id + '/values:batchUpdate',
                    type: 'POST',
                    async: false,
                    contentType: 'application/json',
                    data: JSON.stringify({
                        valueInputOption: 'USER_ENTERED',
                        data: order_data       
                    }),
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));
                    },
                    error: function(data) {
                        console.log(data);
                    },
                    success: function(data) {

                        // Trigger a success notification
                        new Noty({
                            text: '<span>המשימה ' + title + ' עודכנה בהצלחה</span>',
                            type: 'success',
                            layout: 'top',
                            progressBar: true,
                            timeout: 3000,
                            animation: {
                                open: null,
                                close: null
                            } 
                        }).show();
                    }
                });

            } else { // If there are no such tasks, simply trigger a success notification

                new Noty({
                    text: '<span>המשימה ' + title + ' עודכנה בהצלחה</span>',
                    type: 'success',
                    layout: 'top',
                    progressBar: true,
                    timeout: 3000,
                    animation: {
                        open: null,
                        close: null
                    } 
                }).show();
            }
        }
    });
});

// Mobile - reveal and hide the remove button on task left swipe
handleSwipe('.do-tasks__list', '.do-tasks__list-day_item', [
    SWIPE_LEFT,
    SWIPE_RIGHT,
], function (direction, element) {

    if(direction !== 'CLICK') {

        var list     = element.parents('.do-tasks__list-day_items'),
            sortable = Sortable.get(list[0]);

        if(direction == 'SWIPE_LEFT' && $(window).width() <= 1024) {

            // If the calendar button is open for this task, close it and do nothing
            if(element.hasClass('calendar-open')) {
                element.removeClass('calendar-open');

                // Re-enable drag-to-sort in the specific day's tasks list
                sortable.option('disabled', false);

            } else { // If not, open the remove button

                // Disable drag-to-sort in the specific day's tasks list
                sortable.option('disabled', true);

                // Toggle the current one
                element.addClass('remove-open');

                // Hide the rest of the open toggles
                $('.do-tasks__list-day_item').not(element).removeClass('remove-open');
            }
        }

        if(direction == 'SWIPE_RIGHT' && $(window).width() <= 1024) {

            // If the remove button is open for this task, close it and do nothing
            if(element.hasClass('remove-open')) {
                element.removeClass('remove-open');

                // Re-enable drag-to-sort in the specific day's tasks list
                sortable.option('disabled', false);

            } else { // If not, open the calendar button

                // Disable drag-to-sort in the specific day's tasks list
                sortable.option('disabled', true);

                // Toggle the current one
                element.addClass('calendar-open');
            
                // Hide the rest of the open toggles
                $('.do-tasks__list-day_item').not(element).removeClass('calendar-open');
            }
        }
    }
});

// On 'delete' click, remove the task from Google sheets
$('.do-tasks__list').on('mousedown touchend', '.do-tasks__list-day_item__remove, .do-tasks__list-day_item__mobile-delete', function(e) {
    e.preventDefault();

    var button      = $(this),
        parent_row  = button.parents('.do-tasks__list-day_item');

    // Display a Noty popup requesting the action confirmation
    var confirm_noty = new Noty({
        text: '<span>האם למחוק משימה זו?</span>',
        type: 'warning',
        layout: 'top',
        animation: {
            open: null,
            close: null
        },
        buttons: [            
            Noty.button('לא, טעות', 'btn btn-error', function() {

                // If mobile, hide the delete button
                if($(window).width() <= 1024) {
                    parent_row.removeClass('remove-open');
                }

                // Close the confirmation dialog
                confirm_noty.close();
            }),
            Noty.button('כן, אני בטוח', 'btn btn-success', function() {
                
                var title       = parent_row.find('.do-tasks__list-day_item__label').text(),
                    daily_list  = parent_row.parents('.do-tasks__list-day_items'),
                    update_data = {
                        sheet_id: parent_row.attr('data-sheet-id'),
                        tab_id: parent_row.attr('data-tab-id'),
                        tab_name: parent_row.attr('data-tab-name'),
                        sheet_row: parent_row.attr('data-row')
                    };
        
                // Call the Google Sheets API to remove the row
                $.ajax({
                    url: 'https://sheets.googleapis.com/v4/spreadsheets/' + update_data.sheet_id + ':batchUpdate',
                    type: 'POST',
                    async: false,
                    contentType: 'application/json',
                    data: JSON.stringify({
                        requests: [{
                            deleteDimension: {
                                range: {
                                    sheetId: update_data.tab_id,
                                    dimension: 'ROWS',
                                    startIndex: Number(update_data.sheet_row),
                                    endIndex: Number(update_data.sheet_row) + 1
                                }
                            }
                        }],
                    }),
                    beforeSend: function(xhr) {

                        xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));

                        // Close the confirmation dialog
                        confirm_noty.close();

                        // Display the button loader on mobile
                        if($(window).width() <= 1024) {
                            button.find('.do-loader').addClass('is-visible');
                        }
                    },
                    error: function(data) {
                        console.log(data);
                    },
                    success: function(data) {

                        // Remove the row from the frontend
                        parent_row.remove();

                        // Adjust the numbering of the rest of the rows following the current one
                        // which was removed
                        daily_list.find('.do-tasks__list-day_item[data-tab-name="' + update_data.tab_name + '"]').each(function() {

                            if(Number($(this).attr('data-row')) > Number(update_data.sheet_row)) {
                                $(this).attr('data-row', Number($(this).attr('data-row')) - 1);
                            }
                        });

                        // Trigger a success notification
                        new Noty({
                            text: '<div class="container"><span>המשימה ' + title + ' נמחקה בהצלחה</span></div>',
                            type: 'success',
                            layout: 'top',
                            progressBar: true,
                            timeout: 3000,
                            animation: {
                                open: null,
                                close: null
                            } 
                        }).show();
                    }
                });
            }),
        ]
    }).show();
});

// On 'calendar' click, create a new calendar event
$('.do-tasks__list').on('mousedown touchend', '.do-tasks__list-day_item__calendar, .do-tasks__list-day_item__mobile-calendar', function(e) {
    e.preventDefault();

    var button     = $(this),
        parent_row = button.parents('.do-tasks__list-day_item'),
        task_data  = {
            title: parent_row.find('.do-tasks__list-day_item__label').text(),
            sprint_name: parent_row.find('.do-tasks__list-day_item__sprint').text(),
            date: parent_row.attr('data-date')
        };

    // Format the date in Zulu time
    task_data.date = task_data.date.split(' ');

    // Append the time if it was set in the task
    var task_time = '';

    if(task_data.date.length === 2) {
        task_time = 'T' + task_data.date[1] + ':00+02:00';
    }

    task_data.date = task_data.date[0].split('.');
    task_data.date = task_data.date[2] + '-' + task_data.date[1] + '-' + task_data.date[0];
    task_data.date = task_data.date + task_time;

    var data = {
        'summary': task_data.title,
        'description': 'ספרינט משוייך: ' + task_data.sprint_name, // TODO: add phase (after setting up the logic for the timeline)
        'start': {
            'timeZone': 'Asia/Jerusalem'
        },
        'end': {
            'timeZone': 'Asia/Jerusalem'
        }
    };

    if(task_time !== '') {
        data.start.dateTime = task_data.date;
        data.end.dateTime   = task_data.date;

        data.reminders = {
            'useDefault': false,
            'overrides': [
                {
                    'method': 'popup', 
                    'minutes': 60
                },
                {
                    'method': 'popup', 
                    'minutes': 30
                },
                {
                    'method': 'popup', 
                    'minutes': 10
                }
            ]
        };

    } else {
        data.start.date = task_data.date;
        data.end.date   = task_data.date;
    }

    $.ajax({
        url: 'https://www.googleapis.com/calendar/v3/calendars/' + gcalendar_id + '/events',
        type: 'GET',
        async: false,
        type: 'POST',
        async: false,
        contentType: 'application/json',
        data: JSON.stringify(data),
        beforeSend: function(xhr) {

            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));

            // Display the button loader on mobile
            if($(window).width() <= 1024) {
                button.find('.do-loader').addClass('is-visible');
            }
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {

            // Close the button on mobile
            if($(window).width() <= 1024) {
                parent_row.removeClass('calendar-open');

                // Hide the loader as well
                button.find('.do-loader').removeClass('is-visible');
            }

            // Trigger a success notification
            new Noty({
                text: '<span>אירוע לוח השנה נוצר בהצלחה</span>',
                type: 'success',
                layout: 'top',
                progressBar: true,
                timeout: 3000,
                animation: {
                    open: null,
                    close: null
                } 
            }).show();
        }
    });
});

// Assign the custom_order param to the task list based on their order after sorting
function assign_custom_order_param_to_task_list_item(tasks_list) {

    var orders        = tasks_list.map(a => a.custom_order),
        custom_orders = orders.filter(function(val) {
        return val !== 99999
    }),
        auto_orders   = Array.from(Array(tasks_list.length + 1).keys()),
        counter       = 0;

    auto_orders = auto_orders.filter(function(val) {
        return val !== 0 && $.inArray(val, custom_orders) <= -1;
    });

    $.each(tasks_list, function(i) {

        if(! this.is_using_custom_order) {
            this.custom_order = auto_orders[counter];
            counter++;
        }
    });

    return tasks_list;
}

// Implement a drag and drop to reorder tasks by custom order
// inside the daily view
function init_sortable(day_tasks_list) {

    var sort_call_timeout;

    var sortable_config = {
        animation: 150,
        delay: 250,
        sort: true,
        axis: 'x',
        delayOnTouchOnly: true,
        ghostClass: 'do-dragged',
        filter: '.is-complete, .is-late',
        preventOnFilter: false,
        direction: 'horizontal',
        onChoose: function(e) {

            // Clear the Google Sheets order update call timeout
            clearTimeout(sort_call_timeout);

            // Disable the task item's transition (to fix the plugin animation issues)
            $(e.item).addClass('transition-disabled');
        },
        onUnchoose: function(e) {

            // Disable the task item's transition (to fix the plugin animation issues)
            $(e.item).removeClass('transition-disabled');
        },
        onEnd: function(e) {

            // Re-enable the task item's transition (to fix the plugin animation issues)
            $(e.item).removeClass('transition-disabled'); 

            // Add the is-using-custom-order class to the element so we know which one to update
            $(e.item).attr('data-is-using-custom-order', true);

            // Add a timeout for all of the ordering to be complete and then send an update
            // request to Google Sheets with the new tasks order set
            sort_call_timeout = setTimeout(function() {
                dispatch_sorting_sheets_call($(e.item));
            }, 1000);

        },
        onMove: function(e) { // Don't allow to insert after complete items
            return ! $(e.related).hasClass('is-complete') && ! $(e.related).hasClass('is-late');
        }
    }

    if(! day_tasks_list || day_tasks_list.length <= 0) {

        $('.do-tasks__list-day').each(function() {
            day_tasks_list = $(this).find('.do-tasks__list-day_items'); 
            new Sortable(day_tasks_list[0], sortable_config);
        });

    } else {
        new Sortable(day_tasks_list[0], sortable_config);
    }
}

// Get the new tasks order after sorting and update the custom order on Google Sheets
function dispatch_sorting_sheets_call(item) {
    
    var day_tasks_list = item.parents('.do-tasks__list-day_items'),
        sheet_id       = item.attr('data-sheet-id'),
        update_data    = [];

    day_tasks_list.find('.do-tasks__list-day_item').not('.is-complete').not('.is-late').each(function(index) {
        
        // Push the update data
        if($(this).attr('data-is-using-custom-order') == 'true') {

            update_data.push({
                range: $(this).attr('data-tab-name') + '!F' + (Number($(this).attr('data-row')) + 1),
                values: [
                    [index + 1]
                ]
            });
        }
    });

    $.ajax({
        url: 'https://sheets.googleapis.com/v4/spreadsheets/' + sheet_id + '/values:batchUpdate',
        type: 'POST',
        async: false,
        contentType: 'application/json',
        data: JSON.stringify({
            valueInputOption: 'USER_ENTERED',
            data: update_data
        }),
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + Cookies.get('do-google-token'));                    
        },
        error: function(data) {
            console.log(data);
        },
        success: function(data) {

            // Trigger a success notification
            new Noty({
                text: '<span>סדר המשימות עודכן בהצלחה</span>',
                type: 'success',
                layout: 'top',
                progressBar: true,
                timeout: 3000,
                animation: {
                    open: null,
                    close: null
                } 
            }).show();
        }
    });
}

// New task creation drawer - populate the sprint values for the dropdown
function populate_task_creation_sprints_dropdown() {
    
    if(sprints && sprints.length > 0) {

        var dropdown_options = '';

        $.each(sprints, function() {
            dropdown_options += '<option value="' + this.tab_id + '">' + this.name + '</option>';
        });

        $('.do-new-task').find('select[name="do-new-task--sprint"]').html(dropdown_options);
    }
}